<template>
  <v-container fill-height fluid style="position: relative">
    <v-layout align-center justify-center>
      <v-flex xs12 lg10>
        <v-layout column justify-center class="mb-4">
          <h3 class="logo">id</h3>
          <span class="project">{{ $t("auth.new_password") }}</span>
        </v-layout>
        <validation-observer ref="form">
          <v-layout column>
            <v-flex xs12>
              <validation-provider
                vid="confirm"
                :name="$t('auth.new_password')"
                rules="required|min:8|max:16"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="newPassword"
                  :disabled="loading"
                  autocomplete="new-password"
                  :label="$t('auth.new_password')"
                  color="tertiary"
                  :error-messages="errors"
                  :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showNewPassword ? 'text' : 'password'"
                  @click:append="showNewPassword = !showNewPassword"
                />
              </validation-provider>
            </v-flex>
            <v-flex xs12>
              <validation-provider
                :name="$t('auth.re_new_password')"
                rules="required|password:@confirm"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="newPasswordAgain"
                  :disabled="loading"
                  color="tertiary"
                  autocomplete="new-password"
                  :label="$t('auth.re_new_password')"
                  :error-messages="errors"
                  :append-icon="showNewPasswordAgain ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showNewPasswordAgain ? 'text' : 'password'"
                  @click:append="showNewPasswordAgain = !showNewPasswordAgain"
                />
              </validation-provider>
            </v-flex>
            <v-flex xs12>
              <password-validation-messages :password="newPassword" />
            </v-flex>
            <v-flex xs12 class="mt-4">
              <v-btn
                block
                large
                :loading="loading"
                :disabled="!$helpers.isPasswordValid(newPassword)"
                depressed
                rounded
                color="primary"
                @click.stop="validateAndChangePassword"
              >
                {{ $t("auth.change_password") }}
              </v-btn>
            </v-flex>
          </v-layout>
        </validation-observer>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { PASSWORD_REUSE_EXCEPTION } from "@/http/response/status/idenfit";

  export default {
    name: "FirstTimeLogin",
    $_veeValidate: {
      validator: "new"
    },
    components: {
      PasswordValidationMessages: () => import("@/components/PasswordValidationMessages")
    },
    data() {
      return {
        loading: false,
        newPassword: "",
        newPasswordAgain: "",
        showNewPassword: false,
        showNewPasswordAgain: false
      };
    },
    methods: {
      validateAndChangePassword() {
        this.$refs.form.validate().then((valid) => {
          if (valid) {
            this.loading = true;
            this.$api.userService
              .changePassword(null, {
                params: {
                  password: this.newPassword
                }
              })
              .then(({ data: { error } }) => {
                if (error) {
                  const errArgs = [];
                  if (error.code === PASSWORD_REUSE_EXCEPTION) {
                    errArgs.push(error.errorBody.passwordCount);
                  }
                  this.$helpers.showNotification(this.$t(`api_error.${error.code}`, errArgs), "error", true);
                } else {
                  this.$store.commit("auth/clearPasswordExpired");
                  this.$router.replace({ name: "dashboard" });
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    text-align: center;
    display: block;
    color: var(--v-primary-base);
  }

  .project {
    font-family: "Quicksand", sans-serif;
    font-size: 26px;
    color: var(--v-primary-base);
    text-align: center;
    margin-top: -30px;
  }
</style>
